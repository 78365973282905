<template>
    <div>
        <h2>Users</h2>

        <p>
            Click here to download the set of users as an .CSV file:
            <br />
            <button @click="download" class="button-main">Export</button>
        </p>

        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="search">Search</label>
                <input type="text" id="search" v-model="search" class="form-control"
                    placeholder="Search by name or email">
                <button type="button" @click="fetchUsers" class="button">Clear</button>
                <button type="submit" class="button-main">Search</button>
            </div>
        </form>

        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>User ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Medical Role</th>
                    <th>Joined</th>
                    <th>Last logged in</th>
                    <th>Marketing Opt-In</th>
                    <th>Status</th>
                    <th>Voucher type (if any)</th>
                    <th>Voucher expires</th>
                    <th>Total questions answered</th>
                    <th>Discipline with most wrong answers in the last 30 days</th>
                    <th>Is migrated to new system</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.userId }}</td>
                    <!-- <td class="td-image"><img v-if="user.imageUrl" :src="fixUrl(user.imageUrl)" -->
                    <!-- :alt="users.title" width="100"></td> -->
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.occupation }}</td>
                    <td>{{ user.createdAt }}</td>
                    <td>{{ user.lastPlayed }}</td>
                    <td>{{ user.marketingOptIn }}</td>
                    <td><span v-if="user.deleteRequested" class="deleteRequested">DELETE REQUESTED</span></td>
                    <td>{{ user.subscriptionType }}</td>
                    <td>{{ user.giftExpiresAt }}</td>
                    <td>{{ user.answered }}</td>
                    <td>{{ user.worstDiscipline }}</td>
                    <td v-if="user.isMigrated >= 1">{{ user.isMigrated }}</td>
                    <td v-else><a @click="migrate(user.userId)" style="text-decoration:underline;cursor:pointer;">NO</a></td>
                </tr>
            </tbody>
        </table>

        <button @click="prev" v-if="page > 0" class="button-main">Previous page</button>
        Page {{ page + 1 }} of many
        <button @click="next" class="button-main">Next page</button>
    </div>
</template>

<script>
import { fetchUsers, searchUsers, downloadUsersCsv, migrateUser } from '@/repository/apiService';

export default {
    data() {
        return {
            users: [],
            page: 0,
            limit: 100,
            search: null
        };
    },
    created() {
        this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            try {
                const arr = await fetchUsers(this.page * this.limit, this.limit);
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].platform == "deletion_requested") {
                        arr.deleteRequested = true;
                    }
                }
                this.users = arr;
            } catch (error) {
                // Handle errors, e.g., show an error message
            }
        },
        download() {
            downloadUsersCsv();
        },
        async migrate(userId) {
            let result = await migrateUser(userId);
            alert(`Success: ${result.success}\n${result.message}`);
        },
        async submitForm() {
            if (!this.search) return;
            if (this.search.length < 3) {
                alert('Please enter at least 3 characters');
                return;
            }

            try {
                const arr = await searchUsers(this.search);
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].platform == "deletion_requested") {
                        arr.deleteRequested = true;
                    }
                }
                this.users = arr;
            } catch (error) {
                // Handle errors, e.g., show an error message
            }
        }
    }
};
</script>

<style>
.deleteRequested {
    font-weight: bold;
    color: red;
}
</style>
